import React from "react";
import Footer from "src/components/Footer_gr";


class Page extends React.Component {
  render() {
    return (
      <div>
        <div id="disclosureText">
        </div>
        <div id="page">
          <div id="limit">
            <header id="header">
              <div className="mobileHeaderContainer clearfix">
                <a href="/gr/" className="logo">
                  Piz Buin
                </a>
                <button
                  id="btnShowMenu"
                  className="collapse-btn collapse-short-btn right"
                  data-target=".collapse-menu"
                  data-toggle="collapse"
                  type="button"
                />
              </div>
              <nav id="menu" className="collapse-menu collapse-short-menu">
                <ul className="nav-header">
                  <li className="first has-sub-nav">
                    <a className="es_header" href="/gr/our-products/" target="_self">
                      ΤΑ ΠΡΟΪOΝΤΑ ΜΑΣ
                    </a>
                    <ul>
                      <li>
                        <a href="/gr/our-products/moisturising/">
                          Moisturising
                        </a>
                      </li>
                      <li>
                        <a href="/gr/our-products/hydro-infusion/">
                          Hydro Infusion
                        </a>
                      </li>
                      <li>
                        <a href="/gr/our-products/sensitive/">Sensitive</a>
                      </li>
                      <li>
                        <a href="/gr/our-products/tan_protect/">
                          Tan &amp; Protect
                        </a>
                      </li>                      
                      <li>
                        <a href="/gr/our-products/aftersun/">After Sun</a>
                      </li>
                      <li>
                        <a href="/gr/our-products/gallery/">Gallery</a>
                      </li>
                    </ul>
                  </li>
                  <li>
                    <a className="es_header" href="/gr/our-heritage/" target="_self">
                      Η ΜAΡΚΑ PIZ BUIN®
                    </a>
                  </li>
                  <li>
                    <a className="es_header" href="/gr/know_the_sun/" target="_self">
                      ΓΝΩΡΙΣΤΕ ΤΟΝ ΗΛΙΟ
                    </a>
                  </li>
               {/*<li>
                    <a className="es_header" href="/gr/Where-Have-You-Been/" target="_self">
                      Where Have You Been 2019
                    </a>
                  </li>*/}
                </ul>
                <ul className="countryNav">
                  <li
                    className="collapsible has-sub-nav"
                    style={{
                      "list-style": "none"
                    }}
                  >
                    <a className="countries" href="#">
                      ΧΩΡΕΣ
                    </a>
                    <ul className="subMenu">
                      <li>
                        <a href="/en/legal/private-policy/">INT</a>
                      </li>
                      <li>
                        <a href="/uk/legal/private-policy/">UK</a>
                      </li>
                      <li>
                        <a href="/es/legal/politicia-de-privacidad/">ES</a>
                      </li>
                      <li>
                        <a href="/pt/legal/politica_de_privacidade/">PT</a>
                      </li>
                      <li>
                        <a href="/it/legal/private-policy/">IT</a>
                      </li>
                      <li>
                        <a href="/gr/legal/private-policy/">GR</a>
                      </li>
                    </ul>
                  </li>
                </ul>
              </nav>
              <div id="menuExtra" />
            </header>
            <section id="desktop-hero-wrapper" className="index-hero">
              <div id="bgImage">
                <div className="areaInner"></div>
              </div>
            </section>
            <section id="content">
              <div className="innerContent">
                <h2>ΠΟΛΙΤΙΚΗ ΑΠΟΡΡΗΤΟΥ</h2>
                <p> </p>
                <p>
                  Η{" "}
                  <strong>
                   Johnson &amp; Johnson Ελλάς Καταναλωτικά 
                  </strong>{" "}
                  Προϊοντα ΑΕΕ νοιάζεται για τα προσωπικά σας 
                  δεδομένα και θέλει να γνωρίζετε πώς συλλέγουμε, χρησιμοποιούμε 
                  και κοινοποιούμε στοιχεία. Η παρούσα Πολιτική Απορρήτου περιγράφει 
                  τις πρακτικές μας σχετικά με στοιχεία που συλλέγουμε εμείς ή οι 
                  πάροχοι υπηρεσιών μας, μέσω του ιστότοπου ή της εφαρμογής μας 
                  (στο εξής, «υπηρεσία»), η λειτουργία και ο έλεγχος των οποίων 
                  ανήκει σε εμάς και μέσω των οποίων αποκτήσατε πρόσβαση σε αυτήν 
                  την Πολιτική Απορρήτου. Παρέχοντάς μας προσωπικά δεδομένα ή χρησιμοποιώντας αυτήν την υπηρεσία, 
                  αποδέχεστε ότι έχετε διαβάσει και κατανοήσει αυτήν την Πολιτική Απορρήτου.
                  </p>
                <h2>ΧΡΗΣΗ ΑΠΟ ΑΝΗΛΙΚΟΥΣ</h2>
                <p>
                  Η υπηρεσία δεν απευθύνεται σε άτομα ηλικίας κάτω των{" "}
                  <strong>15</strong> ετών και ζητάμε από αυτά τα άτομα να μην παρέχουν προσωπικά δεδομένα 
                  μέσω αυτής της υπηρεσίας. Εάν το παιδί σας έχει υποβάλει προσωπικά 
                  δεδομένα τα οποία θα θέλατε να αφαιρεθούν, επικοινωνήστε 
                  μαζί μας, όπως περιγράφεται παρακάτω, στην ενότητα «Επικοινωνία με εμάς».
                </p>
                <h2>ΣΥΛΛΟΓΗ ΣΤΟΙΧΕΙΩΝ</h2>
                <p>
                Ενδέχεται να σας ζητήσουμε να υποβάλετε προσωπικά δεδομένα ώστε να επωφεληθείτε από ορισμένες λειτουργίες 
                (όπως εγγραφές σε newsletter, συμβουλές/υποδείξεις ή επεξεργασία παραγγελιών) 
                ή να συμμετάσχετε σε κάποια ενέργεια (όπως διαγωνισμούς ή άλλες προσφορές).
                Θα ενημερωθείτε ποια στοιχεία είναι υποχρεωτικά και ποια είναι προαιρετικά.
                </p>
                <p>
                Ενδέχεται να συνδυάσουμε τα στοιχεία που υποβάλλετε με άλλα στοιχεία που 
                έχουμε συλλέξει από εσάς, είτε ηλεκτρονικά είτε όχι, όπως π.χ. το 
                ιστορικό αγορών σας. Ενδέχεται, επίσης, να τα 
                συνδυάσουμε με στοιχεία που λαμβάνουμε σχετικά με εσάς από άλλες 
                συνδεδεμένες εταιρίες της Kenvue.
                </p>
                <p>
                Εάν μας υποβάλετε προσωπικά δεδομένα άλλων ατόμων, θα θεωρηθεί ότι έχετε τη δικαιοδοσία να το κάνετε 
                και ότι μας επιτρέπετε να τα χρησιμοποιήσουμε σύμφωνα με αυτήν την Πολιτική Απορρήτου.
                </p>
                <h2>ΕΥΑΙΣΘΗΤΑ ΔΕΔΟΜΕΝΑ</h2>
                <p>
                Εάν δεν σας το ζητήσουμε ρητά ή επίσημα, σας παρακαλούμε να μη μας αποστέλλετε
                και να μη μας κοινοποιείτε ευαίσθητα προσωπικά δεδομένα (π.χ. αριθμούς 
                κοινωνικής ασφάλισης, στοιχεία που σχετίζονται με φυλετική ή εθνική προέλευση, 
                πολιτικές πεποιθήσεις, θρησκευτικές ή φιλοσοφικές πεποιθήσεις, υγεία και ιατρικές 
                παθήσεις, σεξουαλική ζωή ή σεξουαλικό προσανατολισμό, ποινικό μητρώο, συμμετοχή σε συνδικαλιστική οργάνωση, 
                βιομετρικά ή γενετικά δεδομένα) μέσω της υπηρεσίας μας, ή με οποιονδήποτε άλλον τρόπο.{" "}
                </p>
                <h2>ΑΥΤΟΜΑΤΗ ΣΥΛΛΟΓΗ ΚΑΙ ΧΡΗΣΗ ΣΤΟΙΧΕΙΩΝ</h2>
                <p>
                Εμείς και οι πάροχοι υπηρεσιών μας ενδέχεται να συλλέξουμε αυτόματα ορισμένα στοιχεία, 
                καθώς περιηγείστε στην υπηρεσία. Για λεπτομέρειες σχετικά με τα cookies και άλλες τεχνολογίες παρακολούθησης
                που χρησιμοποιούνται στο πλαίσιο της υπηρεσίας, διαβάστε την 
                <a href="/gr/legal/cookie-policy/"> Πολιτική για τα Cookies</a>. Με εξαίρεση τις περιπτώσεις 
                που επιτρέπεται από το εφαρμοστέο δίκαιο, τοποθετούμε cookies αφού έχουμε λάβει 
                τη συγκατάθεσή σας μέσω του cookie banner ή του κέντρου επιλογών. Η Πολιτική για τα Cookies περιλαμβάνει 
                πληροφορίες σχετικά με τον τρόπο απενεργοποίησης αυτών των τεχνολογιών. 
                </p>
                <p>
                Εμείς και οι πάροχοι υπηρεσιών μας ενδέχεται, 
                επίσης, να συλλέξουμε και να χρησιμοποιήσουμε 
                αυτομάτως στοιχεία με τους εξής τρόπους:
                </p>
                <ul>
                  <li>
                    <strong>Μέσω του προγράμματος περιήγησής σας:</strong> Τα περισσότερα προγράμματα
                    περιήγησης συλλέγουν κάποια στοιχεία, όπως η διεύθυνση ελέγχου πρόσβασης στο μέσο (MAC), 
                    ο τύπος υπολογιστή (Windows ή Mac), η ανάλυση οθόνης, η ονομασία και η έκδοση λειτουργικού 
                    συστήματος ή ο τύπος και η έκδοση του προγράμματος περιήγησης. Εάν χρησιμοποιείτε την 
                    υπηρεσία μέσω φορητής συσκευής, ενδέχεται να συλλέξουμε παρόμοια στοιχεία, όπως ο τύπος 
                    και το αναγνωριστικό της συσκευής αυτής. Αυτά τα στοιχεία τα χρησιμοποιούμε για 
                    να διασφαλίζουμε ότι εκτελούνται κανονικά οι λειτουργίες της υπηρεσίας.
                  </li>
                  <li>
                    <strong>Διεύθυνση IP:</strong> Η διεύθυνσή IP σας είναι ένας
                    αριθμός που εκχωρείται αυτόματα στον υπολογιστή σας από τον
                    πάροχο υπηρεσιών Διαδικτύου σας. Η διεύθυνση IP
                    αναγνωρίζεται και καταγράφεται αυτόματα στα αρχεία
                    καταγραφής του διακομιστή μας, κάθε φορά που ένας χρήστης
                    επισκέπτεται την υπηρεσία μας, καθώς και την ώρα της
                    επίσκεψης και τις σελίδες που αυτός επισκέφτηκε. Η συλλογή
                    διευθύνσεων IP είναι συνήθης πρακτική και πραγματοποιείται
                    αυτόματα από πολλές διαδικτυακές υπηρεσίες. Χρησιμοποιούμε
                    τις διευθύνσεις IP για διάφορους σκοπούς, όπως ο υπολογισμός
                    του επιπέδου χρήσης της υπηρεσίας, η διάγνωση προβλημάτων
                    του διακομιστή και η διαχείριση της υπηρεσίας. Από τη
                    διεύθυνση IP σας μπορούμε, επίσης, να υπολογίσουμε κατά
                    προσέγγιση την τοποθεσία σας.
                  </li>
                  <li>
                    <strong>Στοιχεία συσκευής:</strong> Ενδέχεται να συλλέξουμε
                    στοιχεία σχετικά με τη φορητή συσκευή σας, όπως το μοναδικό
                    αναγνωριστικό συσκευής, για να κατανοήσουμε πώς
                    χρησιμοποιείτε την υπηρεσία.{" "}
                  </li>
                </ul>
                <h2>ΤΡΟΠΟΣ ΧΡΗΣΗΣ ΚΑΙ ΚΟΙΝΟΠΟΙΗΣΗΣ ΣΤΟΙΧΕΙΩΝ</h2>
                <p>
                Χρησιμοποιούμε και κοινοποιούμε τα στοιχεία που μας παρέχετε όπως περιγράφεται στο 
                σημείο συλλογής τους. Δείτε την ενότητα «Επιλογές και πρόσβαση» παρακάτω, 
                για να μάθετε πώς μπορείτε να εξαιρεθείτε από ορισμένες χρήσεις και κοινοποιήσεις μας. {" "}
                </p>
                <p>
                Όπου απαιτείται από την ισχύουσα νομοθεσία, στο σημείο συλλογής στοιχείων θα ζητάμε τη 
                συγκατάθεσή σας για να χρησιμοποιήσουμε τα προσωπικά σας δεδομένα.  Ενδέχεται, 
                επίσης, να χρησιμοποιήσουμε στοιχεία σας, ανάλογα με τις ανάγκες μας: για να 
                εκτελέσουμε μια σύμβαση, να συμμορφωθούμε με μια νομική υποχρέωση (π.χ. λόγω 
                των υποχρεώσεων φαρμακοεπαγρύπνησης) ή για τα νόμιμα επιχειρηματικά μας συμφέροντα.  
                Ενδέχεται, επίσης, να στηριχθούμε σε άλλες νομικές βάσεις, ειδικότερα:
                </p>
                <ul>
                  <li>
                    <strong>
                      Για την παροχή της λειτουργίας της υπηρεσίας και την
                      κάλυψη των αιτημάτων σας.
                      <br />
                      <br />
                    </strong>
                  </li>
                  <li>
                    Για να σας παρέχουμε τη λειτουργία της υπηρεσίας καθώς και
                    τη σχετική εξυπηρέτηση πελατών.
                  </li>
                  <li>
                  Για να ανταποκριθούμε στα ερωτήματά σας και να ικανοποιήσουμε τις απαιτήσεις σας, όπως για να σας αποστέλλουμε έγγραφα που μας ζητήσατε ή ειδοποιήσεις μέσω email.
                  </li>
                  <li>
                  Για να σας αποστέλλουμε σημαντικές πληροφορίες σχετικές με τη τη σχέση που έχουμε μαζί σας ή 
                  σχετικές με την υπηρεσία, ή για αλλαγές στους όρους, τις προϋποθέσεις και τις
                  πολιτικές μας ή άλλες διαχειριστικές πληροφορίες.</li>
                </ul>
                <p>
                Θα υλοποιήσουμε αυτές τις δραστηριότητες για να διαχειριστούμε τη συμβατική σχέση μαζί σας ή/και για να συμμορφωθούμε με μια νομική υποχρέωση.
                </p>
                <ul>
                  <li>
                    <strong>
                    Για την επίτευξη των επιχειρηματικών μας σκοπών.
                      <br />
                      <br />
                    </strong>
                  </li>
                  <li>	Για να αναλύσουμε δεδομένα, για παράδειγμα, με σκοπό τη βελτίωση της αποδοτικότητας της Υπηρεσίας.</li>
                  <li>
                    Για να πραγματοποιήσουμε ελέγχους, προκειμένου να επαληθεύσουμε ότι οι εσωτερικές μας διαδικασίες λειτουργούν όπως απαιτείται και σύμφωνα με τις νομικές, κανονιστικές και συμβατικές απαιτήσεις.
                  </li>
                  <li>
                    Για σκοπούς απάτης και παρακολούθησης ασφαλείας, για παράδειγμα, προκειμένου να ανιχνεύσουμε και να αντιμετωπίσουμε εκ των προτέρων ηλεκτρονικές επιθέσεις  ή προσπάθειες κλοπής ταυτότητας.
                  </li>
                  <li>
                    Για να αναπτύξουμε νέα προϊόντα και υπηρεσίες.</li>
                  <li>
                    Για να βελτιώσουμε ή για να τροποποιήσουμε τον ιστότοπο ή τα προϊόντα και τις υπηρεσίες μας.</li>
                  <li>
                    Για να εντοπίσουμε τάσεις στη χρήση της υπηρεσίας, για παράδειγμα, προκειμένου να κατανοήσουμε ποια μέρη της υπηρεσίας μας, ενδιαφέρουν περισσότερο τους χρήστες.
                  </li>
                  <li>
                    Για να καθορίσουμε την αποτελεσματικότητα των προωθητικών ενεργειών μας, έτσι ώστε να μπορούμε να τις προσαρμόζουμε στις ανάγκες και τα ενδιαφέροντα των χρηστών μας.
                  </li>
                </ul>
                <p>
                Θα πραγματοποιήσουμε αυτές τις δραστηριότητες για να διαχειριστούμε τη συμβατική μας σχέση μαζί σας, για να συμμορφωθούμε με μια νομική υποχρέωση ή/και επειδή έχουμε έννομο συμφέρον.
                </p>
                <ul>
                  <li>
                    <strong>
                      Για την ανάλυση των Προσωπικών Στοιχείων με σκοπό την
                      παροχή εξατομικευμένων υπηρεσιών.
                      <br />
                      <br />
                    </strong>
                  </li>
                  <li>
                    Για να σας κατανοήσουμε καλύτερα, ώστε να μπορούμε να
                    εξατομικεύσουμε τις αλληλεπιδράσεις μας μαζί σας και να
                    παρέχουμε πληροφορίες ή/και προσφορές προσαρμοσμένες στα
                    ενδιαφέροντά σας.
                  </li>
                  <li>
                    Για να κατανοήσουμε καλύτερα τις προτιμήσεις σας, ώστε να
                    μπορούμε να προσφέρουμε το περιεχόμενο μέσω της υπηρεσίας
                    που πιστεύουμε ότι θα σχετίζεται με εσάς και θα σας
                    ενδιαφέρει.
                  </li>
                </ul>
                <p>
                  Θα παρέχουμε εξατομικευμένες υπηρεσίες είτε με τη συγκατάθεσή
                  σας ή επειδή έχουμε έννομο συμφέρον.
                </p>
                <p>
                  Επίσης, κοινοποιούμε στοιχεία που συλλέγονται μέσω της
                  υπηρεσίας στους εξής:
                </p>
                <ul>
                  <li>
                    Σε συνδεδεμένες εταιρείες του ομίλου Kenvue
                    για τους σκοπούς που περιγράφονται στην παρούσα Πολιτική
                    Απορρήτου. Η λίστα με τις συνδεδεμένες εταιρείες του ομίλου
                    Kenvue είναι διαθέσιμη στο{" "}
                    <a
                      href="https://investors.kenvue.com/financials-reports/sec-filings/default.aspx"
                      target="_blank"
                    >
                      https://investors.kenvue.com/financials-reports/sec-filings/default.aspx
                    </a>{" "}
                    - πρόσβαση στα επόμενα αρχεία της Kenvue με την Επιτροπή Χρηματιστηρίου Αξιών. Η Johnson &amp; Johnson
                    Ελλάς Καταναλωτικά Προϊοντα ΑΕΕ είναι το συμβαλλόμενο μέρος
                    που ευθύνεται για τη διαχείριση των από κοινού
                    χρησιμοποιούμενων προσωπικών δεδομένων.
                    <br />
                    <br />
                  </li>
                  <li>
                    Σε τρίτους συνεργάτες μας, με τους οποίους κάνουμε προώθηση
                    με κοινή επωνυμία ή από κοινού μάρκετινγκ.
                    <br />
                    <br />
                  </li>
                  <li>
                    Σε τρίτους παρόχους υπηρεσιών που μας προσφέρουν υπηρεσίες,
                    όπως φιλοξενία και συντονισμό ιστότοπων, φιλοξενία εφαρμογών
                    φορητών συσκευών, ανάλυση δεδομένων, επεξεργασία πληρωμών,
                    εκπλήρωση παραγγελιών, παροχή υποδομών, υπηρεσίες ΙΤ,
                    εξυπηρέτηση πελατών, υπηρεσίες email και ταχυδρομικής
                    αλληλογραφίας, ελέγχους και άλλες υπηρεσίες, ώστε να τους
                    δίνουμε τη δυνατότητα να παρέχουν υπηρεσίες.
                    <br />
                    <br />
                  </li>
                  <li>
                    Στον βαθμό που επιτρέπεται από την ισχύουσα νομοθεσία, σε
                    κάποιο τρίτο μέρος, σε περίπτωση αναδιοργάνωσης,
                    συγχώνευσης, πώλησης, κοινοπραξίας, ανάθεσης, μεταβίβασης ή
                    άλλου είδους διάθεσης του συνόλου ή μέρους των επιχειρήσεών
                    μας, των περιουσιακών μας στοιχείων ή των μετοχών μας
                    (συμπεριλαμβανομένου του ενδεχομένου πτώχευσης ή παρόμοιας
                    διαδικασίας).
                  </li>
                </ul>
                <p>
                  Επιπλέον, ενδέχεται να χρησιμοποιήσουμε και να κοινοποιήσουμε
                  τα στοιχεία σας όπου πιστεύουμε ότι είναι αναγκαίο ή
                  κατάλληλο: (α) για συμμόρφωση με νομικές διαδικασίες ή την
                  ισχύουσα νομοθεσία, η οποία ενδέχεται να περιλαμβάνει
                  νομοθεσίες εκτός της χώρας διαμονής σας, (β) στον βαθμό που
                  επιτρέπεται από την ισχύουσα νομοθεσία, για την ανταπόκριση σε
                  αιτήματα δημοσίων και κρατικών αρχών, η οποία ενδέχεται να
                  περιλαμβάνει αρχές εκτός της χώρας διαμονής σας, (γ) για την
                  εφαρμογή των όρων και των προϋποθέσεών μας και (δ) για την
                  προστασία των δικαιωμάτων, του απορρήτου, της ασφάλειας ή της
                  ιδιοκτησίας μας, των συνδεδεμένων εταιρειών του ομίλου Johnson
                  &amp; Johnson, των δικών σας ή άλλων. Ενδέχεται, επίσης, να
                  χρησιμοποιήσουμε και να κοινοποιήσουμε τα στοιχεία σας με
                  άλλους τρόπους, αφού αποκτήσουμε τη συγκατάθεσή σας.{" "}
                </p>
                <p>
                  Ενδέχεται να χρησιμοποιήσουμε και να κοινοποιήσουμε τα
                  στοιχεία που συλλέγουμε αυτόματα όπως περιγράφεται παραπάνω,
                  στην ενότητα «Αυτόματη συλλογή και χρήση στοιχείων».
                </p>
                <p>
                  Επιπλέον, όπου επιτρέπεται από την ισχύουσα νομοθεσία,
                  ενδέχεται να χρησιμοποιήσουμε και να κοινοποιήσουμε στοιχεία
                  μη προσωπικού χαρακτήρα για οποιονδήποτε σκοπό. Εάν
                  συνδυάσουμε στοιχεία μη προσωπικού χαρακτήρα με προσωπικά
                  δεδομένα (όπως ο συνδυασμός του ονόματός σας με την γεωγραφική
                  σας τοποθεσία), θα χειριστούμε τα συνδυασμένα δεδομένα ως
                  προσωπικά.
                </p>
                <h2>ΕΠΙΛΟΓΕΣ ΚΑΙ ΠΡΟΣΒΑΣΗ</h2>
                <p>
                  <strong>
                    Οι επιλογές σχετικά με τη χρήση και τη κοινοποίηση των
                    προσωπικών σας δεδομένων
                  </strong>
                </p>
                <p>
                  Σας προσφέρουμε επιλογές σχετικά με τη χρήση και τη
                  κοινοποίηση των προσωπικών σας δεδομένων για σκοπούς
                  μάρκετινγκ. Μπορείτε να επιλέξετε να εξαιρεθείτε από τα εξής:
                </p>
                <ul>
                  <li>
                    <strong>Λήψη διαφημιστικών επικοινωνιών :</strong> Εάν δεν επιθυμείτε να λαμβάνετε πλέον 
                    διαφημιστικές επικοινωνίες από εμάς, μπορείτε να εξαιρεθείτε από τη λήψη τους, επικοινωνώντας 
                    μαζί μας <a href="https://www.ccc-consumercarecenter.com/UCUConfiguration?id=a075800001645pB#etd=::00c?Z9W00Y00_RAcvR900ww$">εδώ</a>. Στο αίτημά σας, αναφέρετε το ονοματεπώνυμό σας, προσδιορίστε τις μορφές 
                    διαφημιστικών επικοινωνιών που δεν επιθυμείτε να λαμβάνετε πλέον και συμπεριλάβετε τις 
                    διευθύνσεις στις οποίες αποστέλλονται. Π.χ. εάν δεν επιθυμείτε να λαμβάνετε πλέον email 
                    ή ταχυδρομική αλληλογραφία με διαφημιστικό περιεχόμενο, ενημερώστε μας και συμπεριλάβετε 
                    το ονοματεπώνυμο, τη διεύθυνση email και την ταχυδρομική σας διεύθυνση. Επιπλέον, μπορείτε 
                    να εξαιρεθείτε από τη λήψη διαφημιστικών email ακολουθώντας τις
                    οδηγίες διαγραφής που περιλαμβάνονται σε κάθε τέτοιο μήνυμα.{" "}
                    
                    <br />
                    <br />
                  </li>
                  <li>
                    <strong>Λήψη υπενθυμίσεων:</strong> Εάν δεν επιθυμείτε να λαμβάνετε πλέον ιατρικές υπενθυμίσεις 
                    από εμάς, μπορείτε να εξαιρεθείτε από τη λήψη τους, επικοινωνώντας μαζί μας <a href="https://www.ccc-consumercarecenter.com/UCUConfiguration?id=a075800001645pB#etd=::00c?Z9W00Y00_RAcvR900ww$">εδώ</a>. Στην 
                    απάντησή σας, αναφέρετε το ονοματεπώνυμο και τη διεύθυνση email 
                    ή τον τηλεφωνικό αριθμό σας στο οποίο λαμβάνετε τις υπενθυμίσεις μας.
                    <br />
                    <br />
                  </li>
                  <li>
                    <strong>
                    	Κοινοποίηση των προσωπικών σας δεδομένων με άλλες συνδεδεμένες εταιρείες του ομίλου Kenvue και συνεργάτες:
                    </strong>{" "}
                    Εάν έχετε προηγουμένως επιλέξει να λαμβάνετε διαφημιστικές επικοινωνίες από άλλες συνδεδεμένες 
                    εταιρείες του ομίλου Johnson & Johnson ή συνεργάτες μας, μπορείτε να εξαιρεθείτε στο εξής από την 
                    κοινοποίηση των προσωπικών σας δεδομένων με αυτά τα μέρη για σκοπούς άμεσου μάρκετινγκ, επικοινωνώντας 
                    μαζί μας εδώ. Στην επικοινωνία σας μαζί μας, δηλώστε ότι δεν επιθυμείτε να κοινοποιούμε πλέον τα προσωπικά 
                    σας δεδομένα σε άλλες συνδεδεμένες εταιρείες του ομίλου Johnson & Johnson ή τους συνεργάτες 
                    μας για σκοπούς μάρκετινγκ και συμπεριλάβετε το ονοματεπώνυμο και τη διεύθυνση email σας.{" "}
                  </li>
                </ul>
                <p>
                Θα προσπαθήσουμε να συμμορφωθούμε με το αίτημά σας το συντομότερο δυνατόν. 
                Έχετε υπ' όψη ότι, εάν εξαιρεθείτε όπως περιγράφεται παραπάνω, δεν θα είμαστε 
                σε θέση να αφαιρέσουμε τα προσωπικά σας δεδομένα από τις βάσεις δεδομένων άλλων 
                συνδεδεμένων εταιρειών του ομίλου Johnson & Johnson στις οποίες έχουμε ήδη 
                κοινοποιήσει τα στοιχεία σας (μέχρι την ημέρα που θα εκπληρώσουμε το αίτημα 
                εξαίρεσής σας). Ωστόσο θα καταβάλουμε εύλογες προσπάθειες να ενημερώσουμε τους 
                συνεργάτες μας για το αίτημά σας. Επίσης, λάβετε υπ' όψη ότι, εάν εξαιρεθείτε 
                από τη λήψη διαφημιστικών επικοινωνιών, ενδέχεται να συνεχίσουμε να σας αποστέλλουμε
                σημαντικά μηνύματα που σχετίζονται με
                συναλλαγές και θέματα διαχείρισης και από τα οποία δεν μπορείτε να εξαιρεθείτε.
                </p>
                <p>
                  <strong>
                  Πώς μπορείτε να αποκτήσετε πρόσβαση, να αλλάξετε ή να διαγράψετε τα προσωπικά σας δεδομένα
                  </strong>
                </p>
                <p>
                Εάν επιθυμείτε να ελέγξετε, να διορθώσετε, να ενημερώσετε, να περιορίσετε ή να διαγράψετε 
                τα προσωπικά σας δεδομένα ή εάν επιθυμείτε να ζητήσετε ένα ηλεκτρονικό αντίγραφο αυτών για 
                να τα αποστείλετε σε άλλη εταιρεία (στον βαθμό που σας παρέχονται τέτοια δικαιώματα από την ισχύουσα νομοθεσία), 
                επικοινωνήστε μαζί μας μέσω  αποστολής ενός e-mail στη διεύθυνση <a href="johnson&amp;johnsonHellas@its.jnj.com">johnson&amp;johnsonHellas@its.jnj.com</a>.{" "} 
                Θα απαντήσουμε στο αίτημά σας το συντομότερο δυνατόν και εντός μηνός μετά τη λήψη του. Σε περίπτωση 
                καθυστέρησης στην απάντησή μας, θα ενημερωθείτε
                αμέσως και θα σας γνωστοποιηθεί η ημερομηνία απάντησής μας.
                </p>
                <h2>ΜΕΤΑΒΙΒΑΣΗ ΕΚΤΟΣ ΣΥΝΟΡΩΝ</h2>
                <p>
                Τα προσωπικά σας δεδομένα ενδέχεται να αποθηκευτούν και να υποβληθούν σε επεξεργασία σε οποιαδήποτε 
                χώρα όπου έχουμε εγκαταστάσεις ή παρόχους υπηρεσιών και, χρησιμοποιώντας την υπηρεσία μας ή παρέχοντάς 
                μας τη συγκατάθεσή σας (όπου απαιτείται από τη νομοθεσία), και τα στοιχεία σας ενδέχεται να μεταβιβαστούνε 
                σε χώρες εκτός της χώρας διαμονής σας, συμπεριλαμβανομένων των ΗΠΑ, όπου ενδέχεται να ισχύουν διαφορετικοί 
                κανόνες προστασίας δεδομένων από αυτούς της χώρας σας. Παρ’ όλα αυτά υφίστανται κατάλληλα συμβατικά μέτρα 
                καθώς και άλλα μέτρα για την προστασία των προσωπικών δεδομένων κατά τη διαβίβασή τους στις 
                συνδεδεμένες εταιρείες του ομίλου Kenvue ή τρίτα μέρη σε άλλες χώρες.
                </p>
                <p>
                Η Ευρωπαϊκή Επιτροπή αναγνωρίζει ότι μερικές χώρες εκτός του Ευρωπαϊκού 
                Οικονομικού Χώρου (ΕΟΧ) παρέχουν επαρκές επίπεδο προστασίας δεδομένων σύμφωνα 
                με τα πρότυπα του ΕΟΧ (η πλήρης λίστα αυτών των χωρών είναι διαθέσιμη <a href="https://ec.europa.eu/info/law/law-topic/data-protection/international-dimension-data-protection/adequacy-decisions_el">εδώ</a>. 
                Για τις διαβιβάσεις στοιχείων από τον ΕΟΧ σε χώρες που δεν θεωρούνται κατάλληλες 
                από την Ευρωπαϊκή Επιτροπή, έχουμε βεβαιωθεί ότι υπάρχουν επαρκή μέτρα, όπως η 
                δέσμευση του αποδέκτη από τυποποιημένες συμβατικές ρήτρες της ΕΕ για την 
                προστασία των προσωπικών δεδομένων. Μπορείτε να λάβετε ένα αντίγραφο αυτών 
                των μέτρων επικοινωνώντας με τον υπεύθυνο Προστασίας Δεδομένων όπως 
                περιγράφουν οι οδηγίες της ενότητας «Επικοινωνία με εμάς» παρακάτω. </p>
                <h2>ΑΣΦΑΛΕΙΑ</h2>
                <p>
                Προσπαθούμε να χρησιμοποιούμε εύλογα οργανωτικά, τεχνικά και διοικητικά μέτρα που 
                είναι σχεδιασμένα για να προστατεύουμε τα προσωπικά δεδομένα που έχουμε υπό τον έλεγχό μας. 
                Δυστυχώς, κανείς δεν μπορεί να εγγυηθεί ότι η διαβίβαση δεδομένων μέσω Διαδικτύου και συστημάτων 
                αποθήκευσης δεδομένων είναι 100 % ασφαλής. Εάν έχετε λόγους να πιστεύετε ότι η αλληλεπίδρασή σας 
                με εμάς δεν είναι πλέον ασφαλής (π.χ. εάν πιστεύετε ότι διακυβεύεται η ασφάλεια του λογαριασμού 
                σας σε εμάς), ενημερώστε μας αμέσως σύμφωνα με την ενότητα «Επικοινωνία με εμάς» παρακάτω.
                </p>
                <h2>ΧΡΟΝΙΚΟ ΔΙΑΣΤΗΜΑ ΔΙΑΤΗΡΗΣΗΣ</h2>
                <p>
                Θα κρατήσουμε τα προσωπικά σας δεδομένα για όσο διάστημα χρειάζεται ή επιτρέπεται 
                σύμφωνα με τους σκοπούς για τους οποίους τα συλλέξαμε. Τα κριτήρια που χρησιμοποιούνται 
                για τον προσδιορισμό των χρονικών διαστημάτων διατήρησης περιλαμβάνουν: (i) το χρονικό 
                διάστημα που διαθέτουμε εν εξελίξει σχέση με εσάς και σας παρέχουμε την υπηρεσία, (ii) την 
                ύπαρξη νομικής υποχρέωσης στην οποία υποκείμεθα και (ii) το εάν η διατήρηση είναι συνιστώμενη 
                βάσει της νομικής μας θέσης (π.χ. σχετικά με 
                ισχύουσες περιοριστικές διατάξεις, δικαστικές διαφορές ή κανονιστικές διερευνήσεις).{" "}
                </p>
                <h2>ΙΣΤΟΤΟΠΟΙ ΚΑΙ ΥΠΗΡΕΣΙΕΣ ΤΡΙΤΩΝ</h2>
                <p>
                Αυτή η Υπηρεσία ενδέχεται να περιέχει συνδέσμους προς ιστότοπους 
                τρίτων Η παρούσα Πολιτική Απορρήτου δεν απευθύνεται σε ιστότοπους 
                και υπηρεσίες τρίτων και δεν συνεπάγεται ευθύνη μας για το απόρρητο, 
                τα στοιχεία ή πρακτικές τρίτων, συμπεριλαμβανομένων των τρίτων που 
                διαχειρίζονται κάποιον ιστότοπο ή ηλεκτρονική υπηρεσία (π.χ. άλλες εφαρμογές), 
                που διατίθεται μέσω αυτής της υπηρεσίας ή προς την οποία υπάρχει σύνδεσμος 
                στην υπηρεσία. Η ύπαρξη ή η συμπερίληψη συνδέσμου στην υπηρεσία προς 
                οποιονδήποτε τέτοιου είδους ιστότοπο ή ιδιοκτησία δεν υπονοεί 
                επιδοκιμασία του από εμάς ή άλλες συνδεδεμένες εταιρείες του ομίλου Kenvue.
                </p>
                <h2>ΕΠΙΚΟΙΝΩΝΙΑ ΜΕ ΕΜΑΣ</h2>
                <p>
                  Η Johnson &amp; Johnson Ελλάς Καταναλωτικά Προϊοντα ΑΕΕ, με
                  έδρα τη διεύθυνση Αιγιαλείας &amp; Επιδαύρου 4, Μαρούσι 15125,
                  Αθήνα, είναι η εταιρεία που ευθύνεται για τη συλλογή, τη χρήση
                  και την κοινοποίηση προσωπικών δεδομένων, όπως υπαγορεύεται
                  από την παρούσα Πολιτική Απορρήτου.
                </p>
                <p>
                Εάν έχετε απορίες σχετικά με την παρούσα Πολιτική Απορρήτου, 
                επικοινωνήστε μαζί μας <a href="https://www.ccc-consumercarecenter.com/UCUConfiguration?id=a075800001645pB#etd=::00c?Z9W00Y00_RAcvR900ww$">εδώ</a> ή μέσω επιστολής στην παρακάτω διεύθυνση:{" "}</p>
                <p>
                  Johnson &amp; Johnson Hellas Καταναλωτικά Προϊόντα ΑΕΕ
                  <br />
                  Υπόψη: Τμήματος Εξυπηρέτησης Πελατών
                  <br />
                  Αιγιαλείας &amp; Επιδαύρου 4,
                  <br />
                  Μαρούσι 15125, Αθήνα, Ελλάδα
                </p>
                <p>
                Μπορείτε, επίσης, να επικοινωνήσετε με τον αρμόδιο 
                Προστασίας Δεδομένων που είναι υπεύθυνος για τη χώρα 
                ή την περιφέρειά σας, κατά περίπτωση, στο{" "}
                  <a href="mailto:emeaprivacy@kenvue.com" target="_blank">
                  emeaprivacy@kenvue.com
                  </a>
                  .
                </p>
                <h2>ΥΠΟΒΟΛΗ ΠΑΡΑΠΟΝΟΥ ΣΕ ΚΑΝΟΝΙΣΤΙΚΗ ΑΡΧΗ</h2>
                <p>
                  Μπορείτε να υποβάλετε κάποιο παράπονο στην εποπτεύουσα αρχή 
                  που είναι αρμόδια για τη χώρα ή την περιφέρειά σας. Για τα 
                  στοιχεία επικοινωνίας τέτοιων αρχών, κάντε κλικ{" "}
                  <a
                    href="https://edpb.europa.eu/about-edpb/about-edpb/members_el"
                    target="_blank"
                  >
                    εδώ
                  </a>
                  .
                </p>
                <h2>ΕΝΗΜΕΡΩΣΕΙΣ ΣΤΗΝ ΠΑΡΟΥΣΑ ΠΟΛΙΤΙΚΗ ΙΔΙΩΤΙΚΟΥ ΑΠΟΡΡΗΤΟΥ</h2>
                <p>Ενδέχεται να αλλάξουμε την παρούσα Πολιτική Απορρήτου. Τυχόν αλλαγές στην παρούσα Πολιτική 
                  Απορρήτου ισχύουν από την ανάρτηση της ενημερωμένης Πολιτικής Απορρήτου στην υπηρεσία. 
                  Η χρήση της υπηρεσίας από εσάς, κατόπιν αυτών των αλλαγών, σημαίνει ότι αποδέχεστε την 
                  ενημερωμένη Πολιτική Απορρήτου. Σας συνιστούμε να ελέγχετε τακτικά την Πολιτική 
                  Απορρήτου όταν επισκέπτεστε την υπηρεσία.
                </p>
                <p>
                 Η παρούσα πολιτική ενημερώθηκε για τελευταία φορά στις 02.06.2023.
                </p>
                <p> </p>
              </div>
            </section>
            <div className="clear">
              <br />
            </div>
            <Footer></Footer>
          </div>
          <div id="product-details-overlay" className="itemDescription"></div>
        </div>
        <script src="/assets/js/main.js" />
        <script src="/assets/js/vendor/jquery.fancybox.js" />
        <script src="/assets/js/vendor/jquery.fancybox-media.js" />
        <script src="/assets/js/vendor/iealert.min.js" />
        
        <script
          dangerouslySetInnerHTML={{
            __html:
              '\n$(document).ready(function(){\nvar i = $("#bgImage img").attr(\'src\');\n$("#bgImage").css("background-image","url("+i+")");\n$("#bgImage img").remove();\n});\n'
          }}
        />
      </div>
    );
  }
}

export default Page;
